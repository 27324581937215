import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const componentDidCatch = (error, errorInfo) => {
      // Info: This below condition I used to avoid error for sizing issue on textArea multiline which used in Ads Create and Manage page.
      if (error.message.includes('ResizeObserver loop completed with undelivered notifications'))
        return;
      setHasError(true);
      // You can log the error or perform additional actions here
      console.error(error, errorInfo);
    };

    // Equivalent to getDerivedStateFromError
    window.addEventListener('error', componentDidCatch);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', componentDidCatch);
    };
  }, []); // Empty dependency array ensures the effect runs only once (on mount)

  if (hasError) {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
        <Paper
          elevation={3}
          style={{ padding: '20px', textAlign: 'center' }}>
          <Typography
            variant="h5"
            color="error">
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1">
            We are sorry, but it seems that there is an issue with our website. Please try again
            later.
          </Typography>
          <Typography variant="body1">
            If the problem persists, contact our support team for assistance at{' '}
            <b>example@support.com</b>.
          </Typography>
        </Paper>
      </div>
    );
  }

  return children;
}

export default ErrorBoundary;
